import $ from 'jquery';
import UI from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UI.use(Icons);
import './common.js';
import 'uikit/dist/css/uikit.css';
import '../css/common.css';
// 页面加载
$('body').css('overflow', 'hidden');
document.onreadystatechange = () => {
    if (document.readyState == 'complete') {
        $('body').css('overflow', 'auto');
        $('.page_loading').fadeOut(500);
    }
}
$('.pics-cat a').click(function() {
    $(this).toggleClass('uk-text-success');
});